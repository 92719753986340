import React, { useEffect, useState } from "react";
import {
  FaTwitter,
  FaInstagram,
  FaTelegram,
} from "react-icons/fa";
import FallingMeteors from "../Components/starts";
import AOS from "aos";
import "aos/dist/aos.css";
import pepe from "../assets/pepe.png";
import bonk from "../assets/bonk.png";
import moye from "../assets/moye.png";

const socialIcons = [
  {
    icon: FaTwitter,
    link: "https://x.com/moyememe?s=21&t=Ypk5_Jl-ScmBr89qFS9ZGw",
  },
  {
    icon: FaInstagram,
    link: "https://www.instagram.com/moye.meme?igsh=MWNwc2M1ejh5MmF0bg==",
  },
  { icon: FaTelegram, link: "https://t.me/MoyeMemeCommunity" },
];

function Header() {
  const [animationStart, setAnimationStart] = useState(false);

  useEffect(() => {
    AOS.init({
      duration: 2000,
    });
    setTimeout(() => {
      setAnimationStart(true);
    }, 3000);
  }, []);

  let isMobileDevicce = window.innerWidth <= 1024 ? true : false;
  return (
    <>
      {!isMobileDevicce ? (
        <header className="relative flex flex-col md:flex-row min-h-screen bg-ocean-blue-900 text-white  pt-16 md:pt-28 ">
          <FallingMeteors />

          <div
            data-aos="fade-up"
            className=" flex flex-col items-center justify-center px-6 py-12 md:px-12 lg:px-20 w-1/2 z-20"
          >
            <div className="flex flex-col md:flex-row items-center md:items-start w-full">
              <div className="flex  md:flex-col md:items-center  md:space-y-4 md:mr-6  h-full md:justify-center">
                <div className="flex space-x-4 md:flex-col md:space-x-0 md:space-y-12 ">
                  {socialIcons.map((social, index) => (
                    <a
                      key={index}
                      href={social.link}
                      className="text-white border rounded-full p-3 md:p-4 lg:p-6 hover:bg-white/10"
                    >
                      {React.createElement(social.icon, {
                        className: "text-lg md:text-xl lg:text-2xl",
                      })}
                    </a>
                  ))}
                </div>
              </div>
              <div className="w-1/2 h-px md:w-px md:h-full bg-white mb-8 md:mb-0 md:mr-6"></div>
              <div className=" text-center md:text-left md:h-full md:flex md:flex-col md:justify-center">
                <p className="tracking-widest  text-2xl">INTRODUCING</p>
                <h1 className="text-8xl font-bold mb-4 animate-glow glow-text tracking-wider">
                  MOYE
                </h1>
                <p className=" tracking-wider text-base md:text-lg lg:text-2xl">
                  <span className="font-bold">BONK & PEPE'S BFF ON SOLANA .</span> We don't like Myro and Wif..
               </p>
                <p className=" text-base md:text-xl lg:text-2xl tracking-wider"></p>
              </div>
            </div>
          </div>

          <div className="flex flex-col md:flex-row animate-float w-1/2">
            <div className="flex  flex-col justify-center items-center">
              <img src={pepe} alt="" className="z-20 " />
            </div>

            <div className="flex  flex-col justify-center items-center  ">
              <img src={moye} alt="" className=" z-20 " />
            </div>

            <div className="flex  flex-col justify-center items-center">
              <img src={bonk} alt="" className=" z-20 " />
            </div>
          </div>
        </header>
      ) : (
        <header className="flex flex-col items-center justify-center min-h-screen bg-ocean-blue-900 text-white  ">
          <FallingMeteors />
          <div
            data-aos="fade-up"
            className="flex flex-col items-center justify-center px-6 py-6 mt-10 w-full z-20"
          >
            <div className="flex flex-col md:flex-row items-center md:items-start md:justify-center w-full">
              <div className="text-center">
                <p className="tracking-widest text-xl md:text-lg ">
                  INTRODUCING
                </p>
                <h1 className="tracking-wider  text-6xl md:text-7xl glow-text font-bold mb-4 animate-glow">
                  MOYE
                </h1>
                <p className="tracking-wider font-bold text-xl md:text-2xl">
                  BONK & PEPE'S BFF ON SOLANA
                </p>
                <p className="tracking-wider  text-xl md:text-2xl">
                  We don’t like Myro and Wif.
                </p>
              </div>
            </div>
          </div>

          <div className=" z-20  animate-float  flex justify-center items-center  w-full">
            <div className="flex ">
              <div className="flex  flex-col justify-center items-center">
                <img src={pepe} alt="" className="z-20 " />
              </div>

              <div className="flex  flex-col justify-center items-center ">
                <img src={moye} alt="" className="z-20" />
              </div>

              <div className="flex  flex-col justify-center items-center">
                <img src={bonk} alt="" className=" z-20 " />
              </div>
            </div>
          </div>

          <div className="flex mb-8 flex-col mt-2">
            <div className="border-b border-2px mt-4"></div>
            <div className="flex space-x-4 mt-3">
              {socialIcons.map((social, index) => (
                <a
                  key={index}
                  href={social.link}
                  className="text-white border rounded-full p-3 md:p-4 lg:p-6 hover:bg-white/10"
                >
                  {React.createElement(social.icon, {
                    className: "text-lg md:text-xl lg:text-2xl",
                  })}
                </a>
              ))}
            </div>
          </div>
        </header>
      )}
    </>
  );
}

export default Header;
