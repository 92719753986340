import React, { useEffect, useState } from "react";
import frog from "../assets/suitfrog.png";
import FallingMeteors from "../Components/starts";
import AOS from "aos";
import "aos/dist/aos.css";
import { FaCopy, FaStackExchange } from "react-icons/fa";

function Buy() {
  useEffect(() => {
    AOS.init({ duration: 2000 });
  }, []);

  const [copied, setCopied] = useState(false);

  const CopyHandler = () => {
    navigator.clipboard
      .writeText("73khB8yfmBW5GB26fgzm9wKqZMBwcs5BSpxMfm2PCKiC")
      .then(
        setTimeout(() => {
          setCopied(false);
        }, 1000)
      );
  };

  return (
    <div className="relative  z-20 pt-4 bg-ocean-blue-900">
      <FallingMeteors />
      <div
        className="flex items-center justify-center  text-white pb-20"
        data-aos="fade-up"
      >
        <div className="flex flex-col justify-center items-center space-y-6 my-6  lg:mx-60 lg:py-20 rounded-2xl">
          <div
            onClick={() => {
              CopyHandler();
              setCopied(true);
            }}
            className="w-[75%] flex justify-center border-2 px-1 md:px-4 py-3 space-x-1 md:space-x-4 rounded-full"
          >
            <div className="flex items-center">
              {!copied ? (
                React.createElement(FaCopy, {
                  className: "text-xl ",
                })
              ) : (
                <span className="font-bold text-sm md:text-lg">Copied</span>
              )}
            </div>
            <div className="w-[80%] font-bold text-sm md:text-lg truncate">
              73khB8yfmBW5GB26fgzm9wKqZMBwcs5BSpxMfm2PCKiC
            </div>
          </div>
          <div className="flex space-x-4">
            <button className="bg-blue-800 flex justify-center items-center border-2 md:text-xl font-bold border-white rounded-full px-2 py-2 md:px-4 md:py-3 text-white">
              <a
                href="https://jup.ag/swap/SOL-73khB8yfmBW5GB26fgzm9wKqZMBwcs5BSpxMfm2PCKiC"
                target="_blank"
              >
        
                Buy on Jupiter
              </a>
            </button>
            <button className="bg-blue-800 flex justify-center items-center border-2 md:text-xl font-bold border-white rounded-full px-2 py-2 md:px-4 md:py-3 text-white">
              <a
                href="https://www.dextools.io/app/en/solana/pair-explorer/Gk5tjMEzfG3ncLY6UBjkAoQVcGLP4hxadfTYsjKW2rcJ?t=1719591126743"
                target="_blank"
              >
                Dextools Chart
              </a>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Buy;
