import FAQSection from "../Components/Faq";
import Header from "../Components/Header";
import Navbar from "../Components/Navbar";
import Roadmap from "../Components/RoadMap";
import FullScreenVideo from "../Components/Video";
import Footer from "../Components/Footer";
import Tokenomics from "../Components/Tokenomics";
import BlogSection from "../Components/BlogSection";
import Buy from "../Components/Buy";
function Home() {
  return (
    <div className=" overflow-hidden">
        <Navbar/>
        <Header/>
        <Buy/>
        <FullScreenVideo/>
        <Roadmap/>
        <Tokenomics/>
        <BlogSection/>
        <FAQSection/>
        <Footer/>
    </div>
  );
}

export default Home;
