import React, { useState, useEffect } from "react";
import moonimg from "../assets/moon.png";
import AOS from "aos";
import "aos/dist/aos.css";

function Navbar() {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    AOS.init({ once: true, duration: 1000 });
  }, []);

  const handleMenuItemClick = () => {
    setIsOpen(false); // Close the menu when a menu item is clicked
  };

  const navItems = [
    { label: "Charts", href: "#chart" },
    { label: "Road map", href: "#roadmap" },
    { label: "Tokenomics", href: "#tokenomics" },
    { label: "Media", href: "#media" },
    { label: "Blog", href: "#blog" },
    { label: "FAQs", href: "#faq" },
  ];

  return (
    <nav
      data-aos="fade-down"
      className="bg-ocean-blue-900 md:py-4 md:px-12 z-50 fixed top-0 w-full overflow-x-hidden"
    >
      <div className="flex justify-between items-center px-4 md:px-0">
        <div className="flex w-full justify-between items-center">
          <div className="rounded-full text-4xl flex items-center px-4 py-2 mr-4 text-white font-bold">
            <img src={moonimg} className="h-8 md:h-16" alt="" />
            <h4 className="ml-2 text-3xl md:text-4xl">MOYE</h4>
          </div>
          <ul className="hidden md:flex space-x-6 text-white">
            {navItems.map((item, index) => (
              <li
                className="border border-white rounded-full p-2 hover:bg-white/20"
                key={index}
              >
                <a href={item.href} className="block w-full h-full">
                  {item.label}
                </a>
              </li>
            ))}
          </ul>
          <div className="hidden md:flex border bg-blue-800 border-white rounded p-2 text-white">
            <a
              href="https://jup.ag/swap/SOL-73khB8yfmBW5GB26fgzm9wKqZMBwcs5BSpxMfm2PCKiC"
              className="block w-full h-full"
              target="_blank"
              rel="noopener noreferrer"
            >
              Buy on Jupiter
            </a>
          </div>
        </div>

        <button
          className="md:hidden text-white focus:outline-none"
          onClick={toggleMenu}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-6 w-6"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M4 6h16M4 12h16M4 18h16"
            />
          </svg>
        </button>
      </div>

      {/* Mobile Menu */}
      {isOpen && (
        <ul className="md:hidden bg-ocean-blue-600 py-4 px-6 space-y-4 text-white">
          {navItems.map((item, index) => (
            <li
              className="border p-2 rounded-full hover:bg-white/20 border-white"
              key={index}
            >
              <a
                href={item.href}
                className="block w-full h-full rounded-full"
                onClick={handleMenuItemClick}
              >
                {item.label}
              </a>
            </li>
          ))}
        </ul>
      )}
    </nav>
  );
}

export default Navbar;
